import React from 'react';
import './Wysiwyg.scss';

export const Wysiwyg = ({ content }) => {
  return (
    <section className="wysiwyg-content-block">
      <div className="wrapper">
        <div
          className="wysiwyg-content"
          dangerouslySetInnerHTML={{__html: content}}
        />
      </div>
    </section>
  );
}
