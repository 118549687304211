import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GoogleMapReact from 'google-map-react'
import { decodeEntities } from '../utils/htmlParse'
import './OfficeLocations.scss'

// Google maps native options override
// https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
const createMapOptions = maps => {
  return {
    disableDefaultUI: true,
    scrollwheel: false,
    styles: [
      {
        stylers: [
          {
            saturation: -100,
          },
        ],
      },
    ],
  }
}
const createMapStyles = () => {
  return {
    stylers: [
      {
        saturation: -100,
      },
    ],
  }
}

export const OfficeLocations = () => {
  const {
    wordpressAcfOptions: { options },
  } = useStaticQuery(graphql`
    query {
      wordpressAcfOptions {
        options {
          googleMapsApiKey
          locations {
            locationName
            locationMap {
              lat
              lng
            }
            addressDisplay
            email
            phone
          }
        }
      }
    }
  `)
  const { googleMapsApiKey = null } = options
  console.log('options:', options)
  // If there are no locations to display return null
  if (
    googleMapsApiKey === null ||
    typeof options.locations === 'undefined' ||
    options.locations.length === 0
  ) {
    return null
  }

  return (
    <section className="office-locations">
      <div className="wrapper">
        {options.locations &&
          options.locations.map((loc, index) => {
            const {
              locationName,
              locationMap,
              addressDisplay,
              email,
              phone,
            } = loc
            return (
              <div className="office-location" key={index}>
                <h2 className="office-location-name">{`${decodeEntities(
                  locationName
                )} Office`}</h2>
                <div className="map">
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: googleMapsApiKey }}
                    defaultCenter={{
                      lat: parseFloat(locationMap.lat),
                      lng: parseFloat(locationMap.lng),
                    }}
                    defaultZoom={15}
                    options={createMapOptions}
                    styles={createMapStyles}
                  >
                    <div
                      className="map-marker"
                      lat={locationMap.lat}
                      lng={locationMap.lng}
                    />
                  </GoogleMapReact>
                </div>
                <div className="office-location-details">
                  {addressDisplay && (
                    <span className="address">
                      {decodeEntities(addressDisplay)}
                    </span>
                  )}
                  {phone && (
                    <a className="phone" href={`tel:${phone}`}>
                      {decodeEntities(phone)}
                    </a>
                  )}
                  {email && (
                    <a className="email" href={`mailto:${email}`}>
                      {decodeEntities(email)}
                    </a>
                  )}
                </div>
              </div>
            )
          })}
      </div>
    </section>
  )
}
