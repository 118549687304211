import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { decodeEntities } from '../utils/htmlParse'
import Image from '../components/Image'
import './KeyProjects.scss'

export const KeyProjects = ({ heading }) => {
  const { allWordpressWpProject } = useStaticQuery(graphql`
    query {
      allWordpressWpProject(
        filter: { project_cat: { eq: 73 } }
        limit: 50
        sort: { fields: date, order: DESC }
      ) {
        nodes {
          title
          link
          featuredImage: featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 800, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)
  if (allWordpressWpProject.nodes.length === 0) return null
  return (
    <section className="key-projects">
      <div className="wrapper">
        <h2 className="key-projects-heading">
          {heading ? decodeEntities(heading) : 'Key Projects'}
        </h2>
        <div className="key-projects-container">
          {allWordpressWpProject.nodes.map((project, index) => {
            return (
              <Link className="key-project" to={project.link} key={index}>
                <div className="inner">
                  <div className="image">
                    <Image image={project.featuredImage} />
                  </div>
                  <div className="key-project-details">
                    <h3 className="key-project-title">
                      {decodeEntities(project.title)}
                    </h3>
                    <button className="button" to={project.link}>
                      Learn more
                    </button>
                  </div>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </section>
  )
}
