import React from 'react';
import { Link } from 'gatsby';
import { decodeEntities } from '../utils/htmlParse';
import Image from '../components/Image';
import './LinkTiles.scss';

export const LinkTiles = ({ heading, content, tilesHeading, tiles, className }) => {
  return (
    <section className={`link-tiles-block ${className}`}>
      <div className="wrapper">
        {(heading || content) && (
          <div className="link-tiles-block-text">
            {heading && <h2 className="link-tiles-heading" dangerouslySetInnerHTML={{__html: heading}} />}
            {content && <div className="link-tiles-content" dangerouslySetInnerHTML={{__html: content}} />}
          </div>
        )}
        {tilesHeading && (
          <h3 className="link-tiles-subheading">
            {decodeEntities(tilesHeading)}
          </h3>
        )}
        {tiles && (
          <div className="link-tiles-block-tiles">
            {tiles.map((tile, index) => {
              const { tileTitle, tileContent, tileImage, tileLink } = tile;
              return (
                <Link key={index} className="tile" to={tileLink}>
                  <span className="index" />
                  <Image image={tileImage} />
                  <span className="tile-title">
                    {decodeEntities(tileTitle)}
                  </span>
                  {tileContent && (
                    <div
                      className="tile-content"
                      dangerouslySetInnerHTML={{__html: tileContent}}
                    />
                  )}
                </Link>
              );
            })}
          </div>
        )}
      </div>
    </section>
  );
}
