import React from 'react';
import Image from '../components/Image';
import { IconList } from '../components/IconList';
import './IconListAndImage.scss';

export const IconListAndImage = (props) => {
  const {
    image,
    iconList
  } = props;
  return (
    <section className="icon-list-and-image">
      <div className="wrapper">
        <div className="image">
          <Image image={image} />
        </div>
        {iconList && <IconList iconList={iconList} />}
      </div>
    </section>
  );
}
