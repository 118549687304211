import React from 'react';
// import FormWrapper from '../FormWrapper';
import Leads from '../Leads';
import './Contact.scss';

const Contact = ({ location }) => {
  return (
    <div className="contact-form">
      <Leads id={80} />
    </div>
  );
};

export default Contact;
