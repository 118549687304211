import React from 'react';
import Image from '../components/Image';
import AcfLink from '../components/AcfLink';
import './ImageAndText.scss';

export const ImageAndText = (props) => {
  const {
    heading,
    textPosition,
    backgroundColor,
    content,
    image,
    buttonLink
  } = props;
  return (
    <section className={`image-and-text ${textPosition} ${backgroundColor}`}>
      <div className="wrapper">
        <div className="text">
          <h2 className="heading">{heading}</h2>
          <div className="content" dangerouslySetInnerHTML={{__html: content }} />
          {buttonLink && <AcfLink {...buttonLink} />}
        </div>
        <div className="image">
          <div className="image-container">
            <Image image={image} />
          </div>
        </div>
      </div>
    </section>
  );
}
