import React from 'react';
import './IconList.scss';

export const IconList = ({ iconList = null }) => {
  if (!iconList) return null;
  return (
    <div className="icon-list">
      <ul>
        {iconList.length > 0 && iconList.map(({ icon = 'forge', text = 'Learn more' }, index) => (
          <li key={index} className={icon}>{text}</li>
        ))}
      </ul>
    </div>
  );
}
