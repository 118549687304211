import React from 'react';
import { IconList } from '../components/IconList';
import AcfLink from '../components/AcfLink';
import './IconListAndText.scss';

export const IconListAndText = (props) => {
  const {
    heading,
    content,
    buttonLink,
    iconList
  } = props;
  return (
    <section className="icon-list-and-text">
      <div className="wrapper">
        <div className="text">
          {heading && <h2>{heading}</h2>}
          {content && <div className="content" dangerouslySetInnerHTML={{__html: content}} />}
          {buttonLink && <AcfLink {...buttonLink} />}
        </div>
        {iconList && <IconList iconList={iconList} />}
      </div>
    </section>
  );
}
