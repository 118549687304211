import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { LinkTiles } from './LinkTiles';

export const ProductCategoryTiles = ({ heading, content, categories }) => {
  if (!categories) return null;
  const data = useStaticQuery(graphql`
    query {
      allWordpressWpProductCategory {
        nodes {
          name: title
          link
          wordpress_id
          acf {
            pageContent
            featureImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400, quality: 70, grayscale: true) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            types
            materials
            rating
          }
        }
      }
    }
  `);
  const { allWordpressWpProductCategory } = data;
  const tiles = categories.map((tile) => {
    const categoryWpId = tile.category;
    const thisCategory = allWordpressWpProductCategory.nodes.find(item => item.wordpress_id == categoryWpId);
    const { featureImage = null } = thisCategory.acf;
    const tileLink = thisCategory.link;
    return {
      tileTitle: thisCategory.name,
      tileContent: thisCategory.description,
      tileImage: featureImage,
      tileLink: `${tileLink}`
    };
  })
  return (
    <LinkTiles
      heading={heading}
      content={content}
      tilesHeading="Browse our products by category"
      tiles={tiles}
    />
  );
}
