import React, { useState } from 'react';
import Image from '../components/Image';
import { decodeEntities } from '../utils/htmlParse';
import Modal from '../components/Modal';
import './TeamMembers.scss';

export const TeamMembers = ({ teamMembers }) => {
  if (!teamMembers || teamMembers.length === 0) return null;
  const [modalActive, setModalActive] = useState(false);
  const [activeTeamMember, setActiveTeamMember] = useState(null);

  const showModal = (teamMember) => {
    setModalActive(true);
    setActiveTeamMember(teamMember);
  }

  const closeModal = () => {
    setModalActive(false);
  }

  return (
    <section className="team-members">
      <div className="wrapper">
        {teamMembers.map(teamMember => {
          const { photo, teamMemberName, role } = teamMember;
          return (
            // eslint-disable-next-line
            <div className="team-member" onClick={() => showModal(teamMember)}>
              <Image image={photo} />
              <span className="team-member-name">
                {decodeEntities(teamMemberName)}
              </span>
              <span className="team-member-role">{decodeEntities(role)}</span>
            </div>
          );
        })}
      </div>
      {activeTeamMember && (
        <Modal className="team-member-modal" active={modalActive} closeModal={closeModal}>
          <div className="team-member-container">
            <div className={`team-member-photo ${activeTeamMember?.photo ? "" : "placeholder"}`}>
              <Image image={activeTeamMember.photo} />
            </div>
            
            <div className="team-member-body">
              <span className="team-member-name">
                {decodeEntities(activeTeamMember.teamMemberName)}
              </span>
              <span className="team-member-role">
                {decodeEntities(activeTeamMember.role)}
              </span>
              <div
                className="team-member-bio"
                dangerouslySetInnerHTML={{__html: activeTeamMember.bio}}
              />
            </div>
          </div>
        </Modal>
      )}
    </section>
  );
};
