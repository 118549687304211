import React from 'react';
import './StandoutText.scss';

export const StandoutText = ({ content }) => (
  <section className="standout-text">
    <div className="wrapper">
      <p className="content" dangerouslySetInnerHTML={{__html: content}} />
    </div>
  </section>
);
