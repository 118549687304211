import React from 'react';
import Image from '../components/Image';
import './Banner.scss';

export const Banner = ({ image, heading, subtitle, headingTag = null }) => {
  const HeadingTag = headingTag || "h1"; // used for category page banner
  return (
    <section className="banner">
      <Image image={image} />
      <div className="wrapper">
        <HeadingTag className="banner-heading" dangerouslySetInnerHTML={{__html: heading}} />
        {subtitle && <p dangerouslySetInnerHTML={{__html: subtitle}} />}
      </div>
    </section>
  );
};
