import React from 'react'
import Image from '../components/Image'
import { Carousel } from '../components/Carousel'
import './HomepageBanner.scss'

export const HomepageBanner = ({ heading, subtitle, images }) => {
  return (
    <section className="homepage-banner">
      {images && (
        <div className="banner-images">
          <Carousel
            dotsDisabled
            buttonsDisabled
            infinite
            autoPlay
            duration={500}
            autoPlayInterval={2500}
            id="homepage-banner-carousel"
          >
            {images.map((slide, index) => (
              <Image
                image={slide.image}
                key={`homepage-banner-slide__${index}`}
              />
            ))}
          </Carousel>
        </div>
      )}
      <div className="banner-text">
        <h1 dangerouslySetInnerHTML={{ __html: heading }} />
        {subtitle && <p dangerouslySetInnerHTML={{ __html: subtitle }} />}
      </div>
    </section>
  )
}
