import React, { useEffect, useState } from 'react';
import AcfLink from '../components/AcfLink';
import { Carousel } from '../components/Carousel';
import './CtaTiles.scss';


export const CtaTiles = ({ tiles }) => {
  const [vw, setVw] = useState(0);
  // const [currentIndex, setCurrentIndex] = useState(0);

  // eslint-disable-next-line
  // const thumbItem = (item, index) => <li className="dot"><span onClick={() => setCurrentIndex(index)} /></li>;
  // const slideNext = () => setCurrentIndex(prevIndex => prevIndex + 1);
  // const slidePrev = () => setCurrentIndex(prevIndex => prevIndex - 1);


  const getViewportWidth = () => {
    const newVw = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    setVw(newVw);
  }

  useEffect(() => {
    getViewportWidth();
    window.addEventListener('resize', getViewportWidth);
    return () => {
      window.removeEventListener('resize', getViewportWidth);
    };
  }, []);

  return (
    <section className="cta-tiles">
      <div className="wrapper">
        <Carousel
          enabledBetween={[0, 960]}
          autoHeight
        >
          {tiles.map(tile => {
            const { heading, content, buttonLink } = tile;
            return (
              <div className="tile" key={`cta-tile__${heading}`}>
                <h2>{heading}</h2>
                <div className="content" dangerouslySetInnerHTML={{__html: content}} />
                {buttonLink && <AcfLink {...buttonLink} />}
              </div>
            )
          })}
        </Carousel>
        {/* <div className="custom-slider-nav">
          <button type="button" onClick={slidePrev}>Prev button</button>
          <ul className="dots">
            {[1,2,3,4].map(thumbItem)}
          </ul>
          <button type="button" onClick={slideNext}>Next button</button>
        </div> */}
      </div>
    </section>
  );
}
