import React from 'react';
import './CenteredText.scss';

export const CenteredText = ({ heading, content }) => (
  <section className="centered-text">
    <div className="wrapper">
      {heading && <h2 dangerouslySetInnerHTML={{__html: heading}} />}
      {content && <div className="content" dangerouslySetInnerHTML={{__html: content}} />}
    </div>
  </section>
);
